.checklist-values
  margin-bottom: 0
  margin-left: -$PADDING_PANEL_VIEW
  margin-right: -$PADDING_PANEL_VIEW
  padding-inline-start: 0
  &:after
    content: ""
    clear: both
    display: block
  li
    list-style-type: none
    clear: both
    position: relative
  > .item
    padding: 2px $PADDING_PANEL_VIEW
    display: flex
    justify-content: space-between
    align-items: center
    > .label
      display: flex
      align-items: center
      gap: 0.3rem
      margin-bottom: 0
      line-height: 14px
      font-weight: bold
      > .dataset-icon, > .variant-icon, > .clinical-significance, > .variant-function
        display: inline-block
        vertical-align: middle
        margin-left: -3px
      > .dataset-icon
        #FilterDatasets &
          width: 56px
          &:before
            width: 15px
      > input[type="checkbox"]
        body[data-search-mode="advanced"] &
          display: none
    > .value
      display: block
      padding-left: 1em
      float: right
      line-height: 18px
    &.collapse-view
      display: block
      > .collapsebutton
        left: 3px
        top: 7px
  > .separator
    height: 0
    line-height: 0
    padding: 2px 0
    > hr
      margin: 0
      border: none
      border-top: solid 1px $COLOR_SEPARATOR_SUB
    + .item
      margin-top: 4px
