.question-answer
  margin-bottom: 1em
  margin-top: -$HEIGHT_GLOBAL_MENU - 8px
  padding-top: $HEIGHT_GLOBAL_MENU + 8px

  > .question, > .answer
    position: relative
    padding-left: 27px
    font-size: 14px
    &:before
      width: 20px
      height: 20px
      line-height: 20px
      text-align: center
      display: block
      border-radius: 100%
      position: absolute
      left: 0
      font-size: 14px
      font-weight: bold
  > .question
    line-height: 18px
    padding: 4px 0 0 27px
    &:before
      content: "Q"
      background-color: $COLOR_KEY_DARK1
      color: white
      top: 3px
  > .answer
    &:before
      content: "A"
      color: $COLOR_KEY_DARK1
      border: solid 1px $COLOR_KEY_DARK1
      top: 2px
    > div, > table, > p, > ul, > ol
      margin-bottom: 1em
