#AdvancedSearchView
  .search-field-view
    padding: 14px $PADDING_SIDE
    > .fieldcontainer
      >.field
        margin: 0
        > button
          display: none
  &.-current
   .search-field-view
      > .suggest-view
        top: 44px
        left: 25px

