._margin-bottom
  margin-bottom: .5rem
._margin-left
  margin-left: .5rem
._padding-right-large
  padding-right: 2rem
._align-left
  text-align: left
._align-center
  text-align: center
._align-right
  text-align: right
._flex
  display: flex
._break-avoid
  break-inside: avoid
._width_5em
  width: 5em
._column-span-all
  column-span: all
._nowrap
  overflow-x: hidden
  white-space: nowrap
