$PADDING_RIGHT_ELEMENTS: ($HEIGHT_GLOBAL_MENU - $HEIGHT_NAVIGATION_ITEM) * 0.5;

#GlobalHeader {
  display: flex;
  justify-content: space-between;
  height: $HEIGHT_GLOBAL_MENU;
  background-color: white;
  position: fixed;
  box-shadow: 0 2px 2px rgba(black, 0.2);
  width: 100%;
  top: $HEIGHT_NBDC_COMMON_HEADER;
  z-index: $Z_INDEX_GLOBAL_HEADER;

  > .left {
    display: flex;
    position: relative;
    padding: 10px $PADDING_SIDE;
    top: -2px;

    > h1.heading {
      .link {
        text-decoration: none;

        .logotype {
          width: 101px;
          height: 31px;
          display: block;
          position: relative;
          left: -5px;
          margin-right: 0.3rem;
        }
      }
    }

    span.summary {
      padding-top: 8px;
      font-size: 12px;
      font-weight: normal;
    }

    nav.dropdown-view {
      padding-top: 6px;
      padding-left: 8px;

      > button.button {
        padding: 0 0.7em;

        &:after {
          position: relative;
          left: 5px;
          padding-right: 2px;
          font-size: 0.7rem;
        }
      }

      > .selectshow {
        position: relative;
        top: -20px;
        height: 20px;

        > ul.list {
          padding: 3px 0 3px;

          > li.item {
            > a.link {
              &:before {
                position: relative;
                top: -1px;
                left: -4px;
                padding-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  > .right {
    position: relative;
    font-size: 14px;
    align-self: center;

    nav.menus-view {
      > .menu-wrapper {
        display: flex;

        > .menu {
          display: flex;
          padding: $PADDING_RIGHT_ELEMENTS - 1px 8px;
          margin: 0;

          > li.menu-button {
            color: $COLOR_BLACK;

            &.config {
              cursor: pointer;
            }

            &.login {
              > a.link {
                min-width: 30px;
                color: $COLOR_BLACK;
                text-decoration: none;

                &:hover {
                  color: $COLOR_KEY_DARK1;
                }
              }
            }

            &:hover {
              color: $COLOR_KEY_DARK1;
            }

            > a.link {
              display: block;
              width: 100%;
            }
          }

          + .menu {
            border-left: solid 1px $COLOR_SEPARATOR;
          }

          &.navigation {
            > .item {
              > a.link {
                color: $COLOR_BLACK;
                text-decoration: none;

                &:hover {
                  color: $COLOR_KEY_DARK1;
                  text-decoration: none;
                }
              }

              &.-current {
                > .link {
                  color: $COLOR_KEY_DARK1;
                }
              }
            }
          }
        }
      }
    }

    @media (max-width: 1170px) {
      position: relative;
      > .menu-bar {
        &::before {
          content: $CHAR_BARS;
          font-family: 'FontAwesome';
          font-size: 16px;
          padding-right: 15px;
          display: inline-block;
        }
      }

      > nav.menus-view {
        display: block;
        position: absolute;
        top: 15px;
        right: 15px;

        > .menu-wrapper {
          display: none;
          position: relative;
          gap: 0;
          top: 5px;
          flex-direction: column;
          background-color: white;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
          width: 150px;

          ul.menu {
            flex-direction: column;
            padding: 10px;

            + .menu {
              border-top: solid 1px $COLOR_SEPARATOR;
              border-left: none;
            }

            li.menu-button {
              line-height: 20px;
              padding-left: 5px;

              &.languageswitcher {
                padding-left: 1px;
                margin-bottom: 6px;
              }
            }
          }
        }
      }

      &:hover {
        color: $COLOR_KEY_DARK1;

        > nav.menus-view {
          > .menu-wrapper {
            display: block;
          }
        }
      }
    }
  }
}
