$SIZE: 30px

#SimpleSearchView
  padding: 14px
  > .search-field-view
    > .fieldcontainer, > .examples
      display: none
    > .fieldcontainer >.field
      >input[type="text"]
        width: calc(100% - #{$SIZE - 3px})
        margin-left: -3px
  &.-current
    > .search-field-view
      padding: 14px $PADDING_SIDE 14px
      > .fieldcontainer, > .examples
        display: block
      // > .fieldcontainer
      // > .examples
      > .suggest-view
        top: 43px
        left: 23px
