.menu-button
  display: inline-block
  height: $HEIGHT_NAVIGATION_ITEM
  line-height: $HEIGHT_NAVIGATION_ITEM
  border-radius: $HEIGHT_NAVIGATION_ITEM * .5
  padding: 0 8px
  &.-current
    border: solid 1px $COLOR_KEY_DARK1
    color: $COLOR_KEY_DARK1
  &:hover
    color: $COLOR_KEY_DARK1
