#ResultsView
  padding: 10px $PADDING_SIDE $PADDING_SIDE
  position: relative
  &.-not-found:after
    content: "Not found"
    text-align: center
    display: block
    font-size: 14px
    line-height: 64px
    color: $COLOR_GRAY
  > .header
    white-space: nowrap
    position: relative
    display: flex
    justify-content: space-between
    > .left
      > .heading
        display: inline-block
        font-size: 16px
        margin-right: 8px
        margin-bottom: -2px
      > .status
        display: inline-block
        font-size: 12px
        > .bigger
          font-size: 16px
    > .right
      position: relative
      top:4px
      height: 16px
      list-style: none
      display: flex
      gap: 5px
      z-index: var(--z-index-base)
      > .text
        font-size: 12px
        font-weight: bold
        padding-right: 3px
      > li
        > .button-view
          body[data-has-conditions] &
            opacity: 1
            pointer-events: auto
          &:active
            background: var(--color-key-dark1)
            transform: translateY(.5px)
  > .tablecontainer
    overflow-x: auto
    overflow-y: hidden
    margin-top: -20px
    margin-right: -$PADDING_SIDE
    padding-bottom: 20px
    > .results-view
      > thead
        > tr
          height: 64px
