.button-view
  padding: 0 .8em
  border: none
  line-height: 1.6
  border-radius: .8em
  box-shadow: 0 1px 2px rgba(0, 0, 0, .3)
  position: relative
  background: linear-gradient(to bottom, var(--color-key), var(--color-key-dark1))
  color: white
  font-weight: bold
  cursor: pointer
  &.-weak
    color: var(--color-black)
    background: linear-gradient(to bottom, var(--color-pale-gray), var(--color-light-gray))
  &.-negative
    background: linear-gradient(to bottom, var(--color-disable), var(--color-disable-deep))
  &.-disabled
    opacity: .4
    pointer-events: none
  &:hover
    transform: translateY(.5px)
    box-shadow: 0 0 1px rgba(0, 0, 0, .8)
