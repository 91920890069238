.clinical-significance-view
  > .buttons
    padding: 4px var(--padding-side)
    border-bottom: dotted 1px $COLOR_SEPARATOR_SUB

  > .dataset-title
    font-size: 14px
    font-weight: bold
    padding-top: 2px
    padding-left: var(--padding-side)
    &.clinvar
      color: $COLOR_DATASET_DISEASE_CLINVAR
    &.mgend
      color: $COLOR_DATASET_DISEASE_MGEND

  > hr
    margin: 0
    border: dotted 1px $COLOR_SEPARATOR_SUB

  > .checkboxes
    margin: 0
    font-size: 14px
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    row-gap: 3px
    > li
      display: inline-block
      line-height: 18px
      margin-right: 5px

      &[data-source="clinvar"]
        &[data-value="LP"]
          grid-area: 2 / 1
        &[data-value="PLP"]
          grid-area: 3 / 1
        &[data-value="LPLP"]
          grid-area: 4 / 1
        &[data-value="ERA"]
          grid-area: 5 / 1
        &[data-value="LRA"]
          grid-area: 6 / 1
        &[data-value="URA"]
          grid-area: 7 / 1
        &[data-value="US"]
          grid-area: 1 / 2
        &[data-value="LB"]
          grid-area: 2 / 2
        &[data-value="B"]
          grid-area: 3 / 2
        &[data-value="CI"]
          grid-area: 4 / 2
        &[data-value="DR"]
          grid-area: 5 / 2
        &[data-value="CS"]
          grid-area: 6 / 2
        &[data-value="RF"]
          grid-area: 7 / 2
        &[data-value="A"]
          grid-area: 1 / 3
        &[data-value="PR"]
          grid-area: 2 / 3
        &[data-value="AF"]
          grid-area: 3 / 3
        &[data-value="O"]
          grid-area: 4 / 3
        &[data-value="NP"]
          grid-area: 5 / 3
        &[data-value="AN"]
          grid-area: 6 / 3

      &[data-source="mgend"]
        &[data-value="PLP"]
          grid-area: 1 / 1
        &[data-value="LP"]
          grid-area: 2 / 1
        &[data-value="US"]
          grid-area: 3 / 1
        &[data-value="LB"]
          grid-area: 1 / 2
        &[data-value="B"]
          grid-area: 2 / 2
        &[data-value="DR"]
          grid-area: 3 / 2
        &[data-value="O"]
          grid-area: 1 / 3
        &[data-value="NP"]
          grid-area: 2 / 3

      > label
        font-weight: bold
