=content-width()
  margin: 0 auto
  width: $WIDTH_MIN - $PADDING_SIDE - $PADDING_SIDE
  padding-left: $PADDING_SIDE
  padding-right: $PADDING_SIDE

.common-document
  > .header
    background: $GRADIENT_HEADING
    color: white
    padding: 27px 0 10px
    min-width: $WIDTH_MIN - $PADDING_SIDE - $PADDING_SIDE
    > .title
      font-size: 21px
      line-height: 27px
      +content-width
  > .contents
    font-size: 14px
    line-height: 1.8
    padding: 24px 0 36px
    +content-width
    + .contents
      margin-top: -36px
    > .section
      padding: 24px 0
      &:last-child
        padding-bottom: 60px
        position: relative
        &:after
          content: ""
          display: block
          position: absolute
          width: calc(100% + 8px)
          height: 2px
          background-color: $COLOR_KEY_DARK1
          bottom: 0
          left: -4px
      > .sectiontitle
        font-size: 18px
        color: $COLOR_KEY_DARK3
        position: relative
        &:before, &:after
          content: ""
          display: block
          position: absolute
        &#diseases_jgad000123
          scroll-margin-top: 50px
      .table
        width: 100%
        font-size: 14px
        line-height: 1.2
        th, td
          padding: 4px 4px
          vertical-align: top
        > thead
          > tr
            border-bottom: solid 1px $COLOR_BLACK
        > tbody
          > tr
            border-bottom: solid 1px rgba($COLOR_BLACK, .4)
        > tfoot
          > tr
            border-top: solid 1px $COLOR_BLACK
      .notice
        font-size: 12px
        line-height: 1.4
        margin-top: 8px
      .unordered-list
        list-style: none
        padding-inline-start: 0
        > li
          line-height: 1.6
          padding: 4px 0 4px 1em
          position: relative
          &:before
            content: ""
            display: inline-block
            width: 5px
            height: 5px
            border-radius: 100%
            background-color: $COLOR_KEY
            position: relative
            left: -7px
            top: -3px
      .ordered-list
        counter-reset: item
        list-style: none
        padding-inline-start: 0
        > li
          position: relative
          padding: 4px 0 4px 2em
          &:before
            content: counters(item, ".") " "
            counter-increment: item
            position: absolute
            display: block
            height: 18px
            line-height: 18px
            text-align: center
            border-radius: 9px
            background-color: $COLOR_KEY
            color: white
            right: calc( 100% - 21px )
            top: 7px
            font-weight: bold
            padding: 0 6px
          + li
            margin-top: 1em
          > .heading
            font-size: 14px
            line-height: 1.6
            color: $COLOR_KEY_DARK1
          .subsection
            padding-top: .5em
            line-height: 1.4
            > .subheading
              font-size: 12px
              line-height: 1.2
              color: $COLOR_BLACK
      .variant-function
        line-height: 18px
      img
        max-width: 100%

    &.-two-columns
      > .section
        column-count: 2
        column-gap: 4em
        column-rule: solid 1px rgba($COLOR_KEY, .6)
        widows: 2
        orphans: 2
        word-break: break-all
        hyphens: auto
        text-align: justify
        > .sectiontitle
          column-span: all
        .subsection
          break-inside: avoid

  .results-view
    > tbody
      > tr
        &:hover
          background-color: transparent
