.pathogenicity-editor-view
  &[data-condition-type="pathogenicity_prediction"]
    .body
      padding: 0 0 75px 0 !important
    ul[role="tablist"]
      font-size: 12px
      font-weight: bold
      list-style: none
      margin: 0
      padding: 0
      padding-left: 10px
      display: flex
      gap: 0.1rem
      background: #e2e2e2
      li
        a
          padding: 3px 8px
          display: block
          text-decoration: none
          color: #444
          border-radius: 7px 7px 0 0
          margin-top: 5px
          outline: none
          &[aria-selected="true"]
            background-color: #fff
    .tabs-panels
      padding: 10px
