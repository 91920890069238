@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700,900')

@mixin sprite($width, $height, $x, $y)
  background-image: url('../images/csssprite.png')
  background-repeat: no-repeat
  background-position: #{-$x} #{-$y}
  background-size: 200px 60px

@mixin input-number
  display: inline-block
  text-align: right
  line-height: 18px
  padding: 0 0 0 8px
  border-radius: 9px
  border: solid 1px $COLOR_GRAY
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2) inset
  outline: none

@font-face
  font-family: 'FontAwesome'
  src: url('../fonts/fontawesome-webfont.eot')
  src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), url('../fonts/fontawesome-webfont.woff2') format('woff2'), url('../fonts/fontawesome-webfont.woff') format('woff'), url('../fonts/fontawesome-webfont.ttf') format('truetype'), url('../fonts/fontawesome-webfont.svg') format('svg')
@font-face
  font-family: 'fontello'
  src: url('../fonts/fontello.eot')
  src: url('../fonts/fontello.eot') format('embedded-opentype'), url('../fonts/fontello.woff2') format('woff2'), url('../fonts/fontello.woff') format('woff'), url('../fonts/fontello.ttf') format('truetype'), url('../fonts/fontello.svg') format('svg')

html
  height: 100%
  scroll-behavior: smooth
body
  font-size: 10px
  font-family: 'Roboto Condensed', sans-serif
  color: $COLOR_BLACK
  // height: 100%
  padding-top: $HEIGHT_GLOBAL_MENU + $HEIGHT_NBDC_COMMON_HEADER !important
  -moz-osx-font-smoothing: grayscale
  -webkit-font-smoothing: antialiased
  [data-page="home"] &
    overflow: hidden
  &[data-search-mode="advanced"]
    #FilterAlternativeAlleleFrequency, #FilterVariantCallingQuality, #FilterSIFT, #FilterPolyPhen, #FilterAlphaMissense
      display: none
  &[data-dragging="true"]
    user-select: none

[draggable=true]
  -khtml-user-drag: element

a
  color: $COLOR_KEY_DARK3
  &:link
    color: $COLOR_KEY_DARK3
    text-decoration: underline
  &:active, &:hover
    color: $COLOR_KEY_DARK1

label
  > input
    margin-right: .3em

input
  &[type="radio"], &[type="checkbox"]
    vertical-align: middle

input
  &[type="text"], &[type="email"], &[type="password"], &[type="number"]
    --font-size: 12px
    font-size: var(--font-size)
    display: inline-block
    line-height: var(--height-inline)
    padding: 0 .75em
    // padding: 0 0 0 8px
    border-radius: calc(var(--height-inline) * .5)
    border: solid 1px var(--color-gray)
    box-shadow: 0 1px 1px rgb(0 0 0 / 20%) inset
    outline: none
  &[type="number"]
    padding-right: .25em
    text-align: right
select
  display: inline-block
  height: var(--height-inline)
  line-height: var(--height-inline)

::placeholder, *::part(input-field)::placeholder
  color: $COLOR_GRAY
  font-family: 'Roboto Condensed', sans-serif

.unimplemented
  color: red
  margin-bottom: 0
  &:before
    content: "unimplemented: "
