#ActivityIndicator
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: url(../images/loading.gif) no-repeat 50% 50% rgba(black, .05)
  z-index: $Z_INDEX_ACTIVITY_INDICATOR
  transition: opacity $TRANSITION_DURATION
  pointer-events: none
  &.-hidden
    opacity: 0
    pointer-events: none
