.advanced-search-condition-group-view
  margin: 0 calc(var(--padding-group) * -1 - 1px) 3px 0
  border: solid 1px $COLOR_KEY_DARK3
  background-color: white
  position: relative
  box-shadow: $SHADOW_DEPTH1
  > .logical-operator-switch
    position: absolute
    top: 5px
    left: 20px
    transform: rotate(90deg)
    transform-origin: 0% 0%
  > .container
    padding: var(--padding-group) var(--padding-group) calc(var(--padding-group) - 2px) 22px
  &[data-number-of-child="0"], &[data-number-of-child="1"]
    > .logical-operator-switch
      visibility: hidden
  &.-selected
    &::after
      content: ""
      display: block
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      border: solid 2px $COLOR_SELECTED
      pointer-events: none
  &.-root
    padding-bottom: calc(var(--height-toolbar) + 1px)
    &[data-number-of-child="0"]
      &::before
        content: "Please add a search condition from below menu"
        display: block
        text-align: center
        color: $COLOR_BLACK
        line-height: 12px
        position: relative
        top: 10px
        height: 18px
