.variant-function
  color: white
  border-radius: 9px
  padding: 0 8px
  display: inline-block
  &[data-function="T"], &[data-function="B"]
    background-color: $COLOR_SIGN_SAFE
  &[data-function="LB"]
    background-color: $COLOR_SIGN_NORMAL
  &[data-function="POSSD"], &[data-function="LP"]
    background-color: $COLOR_SIGN_WARNING
  &[data-function="D"], &[data-function="PROBD"]
    background-color: $COLOR_SIGN_DANGEROUS
  &[data-function="U"]
    color: $COLOR_BLACK
    background-color: $COLOR_LIGHT_GRAY
  &[data-function="A"]
    background-color: $COLOR_SIGN_UNKNOWN