.variant-icon
  width: 32px
  height: 26px
  +sprite(39px, 26px, 0, 0)
  &.-snv
    background-position: 0 -20px
  &.-insertion
    background-position: -40px -20px
  &.-deletion
    background-position: -80px -20px
  &.-indel
    background-position: -120px -20px
  &.-substitution
    background-position: -160px -20px
