#SideBar
  box-shadow: 2px 0 3px rgba(black, .2) inset
  padding: $PADDING_SIDEBAR_SIDE
  width: $WIDTH_SIDEBAR * 2
  height: 100%
  overflow-y: auto
  transform: translateX(-297px)
  transition: transform $GUI_DURATION
  white-space: nowrap
  .-drawer-opened &
    padding-bottom: $HEIGHT_BOTTOM_DRAWER_OPENED + $PADDING_SIDEBAR_SIDE
  > .sidebarcontent
    display: inline-block
    vertical-align: top
    width: $WIDTH_SIDEBAR - $PADDING_SIDEBAR_SIDE * 2
    padding-bottom: $PADDING_SIDEBAR_SIDE + $HEIGHT_BOTTOM_DRAWER
    white-space: initial
    > .title
      position: relative
      top: -5px
      color: white
      font-size: 16px
      font-weight: bold
      margin-bottom: 0
      display: flex
      justify-content: space-between
      > .simple
        body[data-search-mode="advanced"] &
          display: none
      > .advanced
        body[data-search-mode="simple"] &
          display: none
      > .button-view
        font-size: 12px
        body[data-search-mode="advanced"] &
          display: none
    + .sidebarcontent
      margin-left: 25px
  body.-rowselected &
    transform: translateX(0)
