$HEIGHT: $HEIGHT_ADVANCED_SEARCH_CONDITION
$HEIGHT_RELATION: 16px
$HEIGHT_VALUE: 18px
$PADDING_VALUES: calc(($HEIGHT - $HEIGHT_VALUE) / 2)
$CORNER_RADIUS: calc($HEIGHT / 2)

.advanced-search-condition-item-view
  --padding-values-vertical: calc((var(--height-advanced-search-condition-values-container) - var(--height-advanced-search-condition-value)) * .5)
  margin-bottom: 3px
  position: relative
  > .body
    border-radius: $CORNER_RADIUS
    background-color: white
    box-shadow: 0 1px 1px rgba(black, .3)
    position: relative
    > .summary
      padding-right: 8px
      color: $COLOR_BLACK
      background-color: #F0F0EF
      font-size: 12px
      display: flex
      align-items: stretch
      min-height: $HEIGHT
      border-radius: $CORNER_RADIUS
      > .classification
        background-color: $COLOR_BLACK
        color: white
        line-height: $HEIGHT
        padding: 0 0 0 $CORNER_RADIUS
        width: 188px
        min-width: 188px
        cursor: grab
        font-weight: bold
        border-top-left-radius: $CORNER_RADIUS
        border-bottom-left-radius: $CORNER_RADIUS
      > .relation
        margin: #{calc(($HEIGHT - $HEIGHT-RELATION) / 2)} #{calc(-1 * $HEIGHT-RELATION / 2)}
        width: $HEIGHT_RELATION
        min-width: $HEIGHT_RELATION
      > .values
        flex-grow: 1
        padding: var(--padding-values-vertical) $CORNER_RADIUS var(--padding-values-vertical) 16px
        flex-wrap: wrap
        align-items: center
        position: relative
        overflow: auto
        max-height: 100px
        &::-webkit-scrollbar
          -webkit-appearance: none
          width: 7px
        &::-webkit-scrollbar-thumb
          border-radius: 4px
          background-color: rgba(0,0,0,.5)
          -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5)
      > .buttons
        display: flex
        > button
          border: none
          padding: 0 4px
          opacity: .4
          &:hover
            opacity: 1
          &::before
            font-family: fontello
        > .edit
          &::before
            content: var(--char-edit)
        > .delete
          &::before
            content: var(--char-delete)
    > .advanced-search-condition-editor-view
      display: none
      border-top: none
      background-color: white
      height: 0
      transition: height $GUI_DURATION
      border-bottom-left-radius: $CORNER_RADIUS
      border-bottom-right-radius: $CORNER_RADIUS
  > .bg
    background: $BACKDROP_BG
    position: fixed
    width: 100vw
    height: 100vh
    top: 0
    left: 0
    opacity: 0
    pointer-events: none
    transition: opacity $TRANSITION_DURATION
  &.-editing
    > .body
      z-index: $Z_INDEX_EDITING_CONDITION + 1
      > .summary
        border-radius: $CORNER_RADIUS $CORNER_RADIUS 0
        > .classification
          border-bottom-left-radius: 0
        > .buttons
          opacity: 0
          pointer-events: none
        > .values
          > condition-item-value-view::part(delete-tag-btn)
            display: inline-block

      > .advanced-search-condition-editor-view
        display: block
        height: auto
    > .bg
      pointer-events: auto
      opacity: 1
      z-index: $Z_INDEX_EDITING_CONDITION
  &[data-relation="eq"], &[data-relation="ne"]
    > .body
      > .summary
        > .relation
          background-color: $COLOR_KEY_DARK1
          height: $HEIGHT_RELATION
          border-radius: 3px
          position: relative
          cursor: pointer
          z-index: 1
          &::before, &::after
            content: ""
            display: block
            position: absolute
          &::before
            width: 8px
            height: 2px
            top: 5px
            left: 4px
            background-color: white
            box-shadow: 0 4px white
  &[data-relation="ne"]
    > .body
      > .summary
        > .classification
          background-color: $COLOR_DISABLE_DEEP
        > .relation
          background-color: $COLOR_DISABLE
          &::after
            height: 12px
            border-left: solid 1px white
            transform: rotate(-20deg)
            top: 2px
            left: 6.5px
  &.-selected
    &::after
      content: ""
      display: block
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      border: solid 2px $COLOR_SELECTED
      border-radius: $CORNER_RADIUS
      pointer-events: none
  &[data-classification="significance"]
    > .body
      > .summary
        > .values
          > .mgend-wrapper
            display: flex
            > span
              font-weight: bold
              min-width: 45px
              &.mgend
                color: $COLOR_DATASET_DISEASE_MGEND
          > .clinvar-wrapper
            display: flex
            > span
              font-weight: bold
              min-width: 45px
              &.clinvar
                color: $COLOR_DATASET_DISEASE_CLINVAR
