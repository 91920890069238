.checkboxes-editor-view
  > .buttons
    padding: 4px var(--padding-side)
    border-bottom: dotted 1px $COLOR_SEPARATOR_SUB
  > .checkboxes
    margin: 0
    font-size: 14px
    line-height: 24px
    > li
      display: inline-block
      line-height: 18px
      margin-right: 12px
      &:last-child
        margin-right: 0
      > label
        margin-bottom: 0
        font-weight: bold
