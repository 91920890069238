#DetailHeader
  padding: 10px $PADDING_SIDE 10px $PADDING_REPORT_LEFT
  background: $GRADIENT_HEADING_SEMITRANSPARENT
  position: sticky
  top: $HEIGHT_GLOBAL_MENU
  z-index: $Z_INDEX_DETAIL_HEADER
  .title-wrapper
    display: inline-block
    vertical-align: bottom
    padding-right: 20px
    > .pagetype
      color: #1f5065
      margin-bottom: 0
      font-size: 14px
      font-weight: bold
      line-height: 16px
    > .title
      color: white
      font-size: 28px
      font-weight: bold
      line-height: 28px
      margin-bottom: 0
