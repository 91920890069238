.advanced-search-condition-editor-view
  --padding-side: calc(var(--height-advanced-search-condition-values-container) * .5)
  border-top: solid 1px $COLOR_SEPARATOR
  color: $COLOR_BLACK
  > .sections
    border-bottom: solid 1px $COLOR_SEPARATOR
    > section
      > header
        padding: 0 var(--padding-side)
        background-color: #cdcdcc
        font-weight: bold
      > .body
        padding: 4px var(--padding-side)
        &[data-type="clinical-significance"]
          padding-top: 2px
        > .row
          display: flex
          place-items: center
        label
          margin-bottom: 0
        .container
          position: relative
          > .loading
            z-index: 9
            position: absolute
            width: 100%
            height: 100%
            top: 0
            left: 0
            display: flex
            justify-content: center
            align-items: center
            > span
              display: block
              width: 100%
              height: 100%
              background: url(../images/loading.gif) no-repeat 50% 50%
  > .buttons
    padding: 3px 0 4px
    text-align: center
    > .button-view
      display: inline-block
