.scroll-bar
  position: absolute
  top: 77px
  right: 0
  width: 16px
  height: calc(100% - 96px)
  > .bar
    position: absolute
    width: 8px
    left: 5px
    height: 100px
    border-radius: 4px
    cursor: grab
    background-color: rgba($COLOR_GRAY, .5)
    opacity: 1
    transition: background-color $GUI_DURATION
    > .indicator
      position: absolute
      background-color: $COLOR_KEY_DARK1
      color: white
      font-size: 12px
      line-height: 18px
      border-radius: 9px
      padding: 0 10px
      white-space: nowrap
      top: calc(50% - 9px)
      right: 13px
      opacity: 0
      pointer-events: none
      transition: opacity $TRANSITION_DURATION
      &:after
        content: ""
        display: block
        position: absolute
        border-left: solid 9px $COLOR_KEY_DARK1
        border-top: solid 4px transparent
        border-bottom: solid 4px transparent
        top: 5px
        right: -7px
      > .position
        font-weight: bold
      > .total
        opacity: .75
        &:before
          content: "/ "
    &.-disabled
      display: none
    &.ui-draggable-dragging
      cursor: grabbing
      background-color: rgba($COLOR_KEY_DARK1, .5)
  &:hover
    > .bar
      background-color: rgba($COLOR_KEY_DARK1, .5)
      > .indicator
        opacity: 1
  &.-dragging
    > .bar
      > .indicator
        opacity: 1
