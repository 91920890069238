$HEIGHT_CHROMOSOME_CONTAINER: $HEIGHT_BOTTOM_DRAWER_OPENED - $HEIGHT_BOTTOM_DRAWER
$TOP_CHROMOSOME: 10px
$BOTTOM_CHROMOSOME: 16px

.drawer-view
  width: 100%
  position: fixed
  background-color: $COLOR_BLACK
  color: white
  z-index: $Z_INDEX_DRAWER
  bottom: $HEIGHT_BOTTOM_DRAWER - $HEIGHT_BOTTOM_DRAWER_OPENED
  transition: bottom $TRANSITION_DURATION
  .-drawer-opened &
    bottom: 0
  > .header
    padding: 0 #{$PADDING_SIDE - 2px}
    line-height: $HEIGHT_BOTTOM_DRAWER
    height: $HEIGHT_BOTTOM_DRAWER
    border-top: solid 1px rgba(white, .4)
    border-bottom: solid 1px rgba(black, .2)
    cursor: pointer
    > .mark
      display: inline-block
      position: relative
      vertical-align: middle
      margin-right: .7em
      margin-top: -.8em
      width: 7px
      height: 7px
      border-top: solid 1px white
      border-left: solid 1px white
      transform: rotate(45deg)
      transition: transform 0.2s, top 0.2s
      transform: rotate(225deg)
      .-drawer-opened &
        transform: rotate(45deg)
        margin-top: -.3em
    > .heading
      display: inline-block
      font-size: 15px
      font-weight: bold
  > .configuration
    position: absolute
    top: 5px
    right: $PADDING_SIDE - 2px
    font-size: 12px
    pointer-events: none
    opacity: 0
    transition: opacity $TRANSITION_DURATION
    max-width: calc(100vw - 120px)
    text-align: right
    white-space: nowrap
    overflow-x: auto
    .-drawer-opened &
      pointer-events: auto
      opacity: 1
    > .form-parts
      display: inline-block
      + .form-parts
        margin-left: .75em
      > .togglebuttons
        background-color: rgba(black, .5)
        > .button
          &:hover
            color: $COLOR_KEY
      > .select
        background-color: rgba(black, .5)
        color: white
  > .content
    position: relative
    overflow: hidden
    > .chromosomes
      padding: $TOP_CHROMOSOME $PADDING_SIDE $BOTTOM_CHROMOSOME
      height: $HEIGHT_CHROMOSOME_CONTAINER - $TOP_CHROMOSOME - $BOTTOM_CHROMOSOME
      text-align: center
      white-space: nowrap
      overflow-x: auto
      box-sizing: content-box
      > .chromosome-view
        display: inline-block
        vertical-align: top
