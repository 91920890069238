.columns-editor-view
  > .body
    padding: 0 !important
    display: flex
    overflow: auto
    > .columns
      display: flex
      > .column
        padding: 4px 0
        max-height: 200px
        overflow-y: auto
        border-right: solid 1px $COLOR_SEPARATOR
        > .messages-view
          margin-left: 4px
          font-size: 12px
          > .note
            padding-left: 24px
        > ul
          margin: 0
          padding: 0
          > li
            padding-left: 12px
            padding-right: 18px
            position: relative
            list-style: none
            font-size: 14px
            line-height: 24px
            white-space: nowrap
            > .arrow
              display: block
              position: absolute
              top: 7px
              right: 6px
              width: 0
              height: 0
              border-style: solid
              border-width: 4px 0 4px 6px
              border-color: transparent transparent transparent $COLOR_BLACK
              opacity: .2
              transition: opacity $GUI_DURATION
              cursor: pointer
              &:hover
                opacity: 1
            &.-selected
              background-color: #f0f0ef
              > .arrow
                opacity: 1
            > label
              margin-bottom: 0
              > input
                vertical-align: middle
              > .dataset-icon
                margin-right: 4px
              > span
                font-weight: bold
              > .lock
                &::before
                  content: $CHAR_LOCK
                  font-family: FontAwesome
                  color: $COLOR_BLACK
                  opacity: .4
                  font-size: 14px
                  margin-right: 4px
