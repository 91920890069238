.clinical-significance
  color: $COLOR_KEY_DARK3
  &:before
    width: 18px
    line-height: 18px
    height: 18px
    display: inline-block
    margin-right: 3px
    border-radius: 9px
    border-width: 1px
    border-style: solid
    border-color: transparent
    text-align: center
    font-size: 10px
    font-weight: bold
    vertical-align: top
  &[data-value="P"]:before, &[data-value="PLP"]:before
    content: "P"
  &[data-value="LP"]:before, &[data-value="LPLP"]:before
    content: "LP"
  &[data-value="P"]:before, &[data-value="LP"]:before
    color: $COLOR_SIGN_DANGEROUS
    border-color: $COLOR_SIGN_DANGEROUS
    background-color: $COLOR_SIGN_DANGEROUS_LIGHT
  &[data-value="PLP"]:before, &[data-value="LPLP"]:before
    color: $COLOR_SIGN_LOWDANGEROUS
    border-color: $COLOR_SIGN_LOWDANGEROUS
    background-color: $COLOR_SIGN_LOWDANGEROUS_LIGHT
  &[data-value="ERA"]:before, &[data-value="LRA"]:before, &[data-value="URA"]:before
    content: "RA"
    color: $COLOR_SIGN_WARNING
    border-color: $COLOR_SIGN_WARNING
    background-color: $COLOR_SIGN_WARNING_LIGHT
  &[data-value="US"]:before
    content: "US"
    color: $COLOR_SIGN_UNKNOWN
    border-color: $COLOR_SIGN_UNKNOWN
    background-color: $COLOR_SIGN_UNKNOWN_LIGHT
  &[data-value="LB"]:before
    content: "LB"
    color: $COLOR_SIGN_NORMAL
    border-color: $COLOR_SIGN_NORMAL
    background-color: $COLOR_SIGN_NORMAL_LIGHT
  &[data-value="B"]:before
    content: "B"
    color: $COLOR_SIGN_SAFE
    border-color: $COLOR_SIGN_SAFE
    background-color: $COLOR_SIGN_SAFE_LIGHT
  &[data-value="CI"]:before
    content: "CI"
    color: $COLOR_SIGN_MODIFIER
    border-color: $COLOR_SIGN_MODIFIER
    background-color: $COLOR_SIGN_MODIFIER_LIGHT
  &[data-value="DR"]:before
    content: "DR"
  &[data-value="CS"]:before
    content: "CS"
  &[data-value="RF"]:before
    content: "RF"
  &[data-value="A"]:before
    content: "A"
  &[data-value="PR"]:before
    content: "PR"
  &[data-value="AF"]:before
    content: "AF"
  &[data-value="DR"], &[data-value="CS"], &[data-value="RF"], &[data-value="A"], &[data-value="PR"], &[data-value="AF"]
    &:before
      color: $COLOR_SIGN_OTHER
      border-color: $COLOR_SIGN_OTHER
      background-color: $COLOR_SIGN_OTHER_LIGHT
  &[data-value="O"]:before
    content: "O"
  &[data-value="NP"]:before
    content: "NP"
  &[data-value="AN"]:before
    content: "AN"
  &[data-value="O"]:before, &[data-value="NP"]:before, &[data-value="AN"]:before
    color: $COLOR_GRAY
    border-color: $COLOR_GRAY
    background-color: white
