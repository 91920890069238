$SIZE_NUMBER: 18px
$HEIGHT_UPPER: 18px
$WIDTH_VIEW: 36px
$WIDTH_CHROMOSOME: 12px
$WIDTH_INDICATOR1: 36px
$WIDTH_INDICATOR2: 28px
$WIDTH_BAND_LABEL: 32px

.chromosome-view
  height: 100%
  width: $WIDTH_VIEW
  position: relative
  text-align: left
  transition: width $GUI_DURATION
  > .upper
    height: $HEIGHT_UPPER
    > .no
      text-align: center
      font-size: 12px
      font-weight: bold
      width: $SIZE_NUMBER
      height: $SIZE_NUMBER
      line-height: $SIZE_NUMBER
      border-radius: $SIZE_NUMBER * .5
      display: inline-block
      background-color: rgba(black, .25)
      position: relative
      left: ($WIDTH_VIEW - $SIZE_NUMBER) * .5
      transition: left $GUI_DURATION
      cursor: pointer
  > .lower
    position: relative
    > .filteredregion, > .displayregion, > .selectedregion
      position: absolute
      width: $WIDTH_INDICATOR1
      left: ($WIDTH_VIEW - $WIDTH_INDICATOR1) * .5
      opacity: 0
      transition: opacity $GUI_DURATION, height $GUI_DURATION, top $GUI_DURATION, left $GUI_DURATION
      &.-shown
        opacity: 1
    > .filteredregion
      background-color: rgba($COLOR_SELECTED, .4)
    > .displayregion
      border: solid 1px $COLOR_SELECTED
      border-radius: 2px
      width: $WIDTH_INDICATOR2
      left: ($WIDTH_VIEW - $WIDTH_INDICATOR2) * .5
    > .chromosome
      width: $WIDTH_CHROMOSOME
      display: block
      position: relative
      left: ($WIDTH_VIEW - $WIDTH_CHROMOSOME) * .5
      filter: drop-shadow(0 4px 4px rgba(black, .5))
      transition: width $GUI_DURATION, left $GUI_DURATION
      > .subband
        cursor: pointer
        [data-is-selecting-chromosome="true"] &
          opacity: .3
        &.-selected
          opacity: 1
        > .subbandrect
          fill: url(#chromosome-gneg)
          transition: fill $GUI_DURATION
          &.acen
            fill: url(#chromosome-acen)
          // &.gneg
          //   fill: url(#chromosome-gneg)
          &.gpos25
            fill: url(#chromosome-gpos25)
          &.gpos50
            fill: url(#chromosome-gpos50)
          &.gpos75
            fill: url(#chromosome-gpos75)
          &.gpos100
            fill: url(#chromosome-gpos100)
          &.gvar
            fill: url(#chromosome-gpos100)
          &.stalk
            fill: url(#chromosome-stalk)
        &:hover, &.-hover
          opacity: 1
          > .subbandrect
            fill: $COLOR_SELECTED
      > .band
        cursor: pointer
        [data-is-selecting-chromosome="true"] &
          opacity: .3
        &:hover, &.-selected
          opacity: 1
        > .bandtext
          font-size: 12px
          line-height: 12px
          fill: white
        > .line
          stroke: white
          stroke-width: .5px
          fill: transparent
        &:hover
          > .bandtext
            fill: $COLOR_SELECTED
          > .line
            stroke: $COLOR_SELECTED

    > .selectedregion
      background-color: rgba($COLOR_SELECTED, .15)
      border-radius: 1px
.-show-band-label
  .chromosome-view
    width: $WIDTH_VIEW + $WIDTH_BAND_LABEL
    > .upper
      > .no
        left: ($WIDTH_VIEW + $WIDTH_BAND_LABEL - $SIZE_NUMBER) * .5
    > .lower
      > .filteredregion, > .displayregion, > .selectedregion
        left: ($WIDTH_VIEW + $WIDTH_BAND_LABEL - $WIDTH_INDICATOR1) * .5
      > .displayregion
        left: ($WIDTH_VIEW + $WIDTH_BAND_LABEL - $WIDTH_INDICATOR2) * .5
      > .chromosome
        width: $WIDTH_CHROMOSOME + $WIDTH_BAND_LABEL
        left: ($WIDTH_VIEW + $WIDTH_BAND_LABEL - $WIDTH_CHROMOSOME) * .5
