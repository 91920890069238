.collapse-view
  position: relative
  > .collapsebutton
    cursor: pointer
    position: absolute
    transition: top $GUI_DURATION
    &:before
      content: ""
      display: block
      width: 7px
      height: 7px
      border-top-width: .1rem
      border-left-width: .1rem
      border-style: solid
      border-color: #fff
      border-right: none
      border-bottom: none
      transform-origin: 25% 50%
      transform: rotate(45deg)
      transition: transform $GUI_DURATION
  &.-collapsed
    > .collapsebutton
      &:before
        transform: rotate(225deg)
    > .collapsecontent
      display: none

  &.-hierarchic
    > .collapsebutton
      &:before
        transform: rotate(90deg)
        border-top: 4px solid transparent
        border-left: 5px solid $COLOR_BLACK
        border-bottom: 4px solid transparent
    > .collapsecontent
      padding-left: 1rem
    &.-collapsed
      > .collapsebutton
        &:before
          transform: rotate(0deg)
    &.-unstructured
      &[data-collapse-depth="1"]
        margin-left: 12px
      &[data-collapse-depth="2"]
        margin-left: 24px
      &[data-collapse-depth="3"]
        margin-left: 36px
