$PADDING_SIDE: 14px

.suggest-view
  background-color: white
  z-index: $Z_INDEX_SUGGEST_VIEW
  box-shadow: 0 2px 6px rgba(0, 0, 0, .2)
  display: flex
  font-size: 14px
  //line-height: 24px
  color: $COLOR_BLACK
  > .column
    + .column
      border-left: solid 1px $COLOR_SEPARATOR_SUB
    > .title
      color: $COLOR_KEY_DARK1
      font-size: 12px
      font-weight: normal
      line-height: 16px
      margin: 4px 0 0
      padding: 5px $PADDING_SIDE 0
      &:first-letter
        text-transform: capitalize
    > .empty
      padding: 0 $PADDING_SIDE
      height: 5rem
      width: 20rem
      color: $COLOR_GRAY
      display: flex
      justify-content: center
      align-items: center
    >.loading
      display: flex
      justify-content: center
      align-items: center
      height: 5rem
      width: 20rem
      >span
        display: block
        width: 100%
        height: 100%
        background: url(../images/loading.gif) no-repeat 50% 50%
    >.error
      height: 5rem
      width: 20rem
      padding: 0 $PADDING_SIDE
      color: $COLOR_GRAY
      display: flex
      justify-content: center
      align-items: center
    > .list
      margin: 10px 0
      padding-left: 0
      list-style: none
      overflow-y: auto
      > .item
        cursor: pointer
        padding: 0 $PADDING_SIDE
        line-height: 26px
        cursor: pointer
        transition: background-color $GUI_DURATION
        > em
          background-color: var(--color-suggest)
          font-style: normal
        &:first-child
          padding-top: 4px
          height: 30px
        &:hover
          background-color: rgba($COLOR_SELECTED, .2)
        &.-disabled
          cursor: auto
          &:hover
            background-color: inherit
        > .sub
          padding-left: .5em
          color: rgba($COLOR_BLACK, .5)
          font-size: 12px
        &.-selected
          background-color: rgba($COLOR_SELECTED, 1)
      .loading
        position: absolute
        width: 100%
        height: 100%
        top: 0
        left: 0
        display: flex
        justify-content: center
        align-items: center
        > span
          display: block
          width: 100%
          height: 100%
          background: url(../images/loading.gif) no-repeat 50% 50%

