.dataset-icon
  height: 18px
  font-family: fontello
  position: relative
  &:before
    content: $CHAR_DATASET
    margin-right: 2px
    position: relative
    left: 2px
    display: inline-block
    height: 18px
  > .properties
    font-size: 10px
    display: inline-flex
    flex-direction: column
    justify-content: center
    font-family: "Roboto Condensed"
    font-weight: lighter
    line-height: 9px
    position: relative
    left: -2px
    top: -1px
    vertical-align: middle
    height: 20px
    transform: scale(.9)
    &::before, &::after
      display: block
      height: 10px
  // color
  &[data-dataset="jga_wgs"],&[data-dataset="jga_wes"], &[data-dataset="jga_snp"]
    &:before
      color: $COLOR_DATASET_JGA
  &[data-dataset="tommo"]
    &:before
      color: $COLOR_DATASET_TOMMO
  &[data-dataset="hgvd"], &[data-dataset="mgend"]
    &:before
      color: $COLOR_DATASET_HGVD
  &[data-dataset="ncbn"]
    &:before
      color: $COLOR_DATASET_NCBN
  &[data-dataset="gem_j_wga"]
    &:before
      color: $COLOR_DATASET_GEM_J
  &[data-dataset="bbj"]
    &:before
      color: $COLOR_DATASET_BBJ
  &[data-dataset="gnomad_genomes"], &[data-dataset="gnomad_exomes"]
    &:before
      color: $COLOR_DATASET_FOREIGN
  // Disease
  &[data-dataset="mgend"], &[data-dataset="clinvar"]
    &::before
      content: "\f067"
      font-family: FontAwesome
      color: $COLOR_DATASET_DISEASE
      font-size: 14px
    > .properties
      &::before
        color: $COLOR_DATASET_DISEASE
        content: "Disease"
  // WGS
  &[data-dataset="jga_wgs"],&[data-dataset="tommo"], &[data-dataset="ncbn"], &[data-dataset="gem_j_wga"], &[data-dataset="gnomad_genomes"]
    > .properties
      &::after
        content: "WGS"
  // WES
  &[data-dataset="jga_wes"], &[data-dataset="hgvd"], &[data-dataset="gnomad_exomes"]
    > .properties
      &::after
        content: "WES"
  // SNP
  &[data-dataset="jga_snp"], &[data-dataset="bbj"]
    > .properties
      &::after
        content: "SNP"
