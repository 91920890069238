.results-view
  white-space: nowrap
  font-size: 14px
  line-height: 18px
  border-right: solid $PADDING_SIDE white
  width: 100%
  > thead
    > tr
      border-bottom: solid 2px $COLOR_BLACK
      height: 36px
      > th
        position: relative
        > p
          position: absolute
          transform-origin: 0 100%
          transform: rotate(-20deg)
          bottom: -17px
          left: 9px
          font-size: 12px
        &.separator
          border-bottom: solid 2px white
          &:before
            content: ""
            width: 28px
            display: block
      &.groupingrow
        border-bottom: none
        height: 4px
        > th
          > p
            bottom: -51px
            border-left: solid 1px rgba($COLOR_BLACK, .5)
            line-height: 1
            padding-left: 5px
            padding-bottom: 15px
            left: 0px
            font-weight: normal
  > tbody
    > tr
      border-bottom: solid 1px $COLOR_SEPARATOR
      transition: background $GUI_DURATION
      height: 27px
      > td
        min-width: 2em
        padding: 2px 10px 2px 6px
        transition: background $GUI_DURATION
        &[data-remains]:after
          content: "+" attr(data-remains)
          display: inline-block
          font-size: 10px
          line-height: 14px
          border: solid 1px $COLOR_GRAY
          border-radius: 7px
          background-color: lighten($COLOR_GRAY, 35%)
          padding: 0 4px
          margin-left: 4px
          vertical-align: middle
        &[data-remains="0"]:after, &[data-remains=""]:after
          display: none
        &.separator
          border-bottom: solid 1px white
          position: relative
          color: $COLOR_KEY
          &:before
            content: ""
            display: block
            height: 11px
            border-left: solid 1px rgba($COLOR_BLACK, .5)
            position: absolute
            transform: rotate(-20deg) translateY(-6px)
            top: 50%
            left: 18px
        > .icon
          &[data-remains]::before
            content: "+" attr(data-remains)
            display: inline-block
            font-size: 10px
            line-height: 14px
            border: solid 1px $COLOR_GRAY
            border-radius: 7px
            background-color: lighten($COLOR_GRAY, 35%)
            padding: 0 4px
            margin-left: 4px
            vertical-align: middle
          &[data-remains="0"]::before, &[data-remains=""]::before
            display: none
          &[data-mgend="true"]::after
            content: ""
            position: relative
            margin-left: 6px
            top: 3px
            display: inline-block
            width: 16px
            height: 16px
            background: url(../images/mgend-logo.png) no-repeat
            background-size: contain
        > .consequence-item, > .dataset-icon, > .clinical-significance, > .variant-function
          display: inline-block
          vertical-align: middle
        &:last-child
          padding-right: 6px

        > logarithmized-block-graph-frequency-view
          margin-right: -2px

      &:hover
        background-color: rgba($COLOR_SELECTED, .2)
      &.-selected
        background-color: rgba($COLOR_SELECTED, .4)
      &.-loading
        > td
          background: url(../images/loading.gif) no-repeat 50% 50%, rgba(black, .05)
          background-size: 30px 10px
      &.-out-of-range
        display: none
