#AdvancedSearchBuilderView
  --padding-group: 6px
  --height-toolbar: 14px
  padding: 14px $PADDING_SIDE 14px
  user-select: none
  > .inner
    .advanced-search-condition-item-view
      + .advanced-search-condition-group-view
        margin-top: 5px
    .advanced-search-condition-group-view
      + .advanced-search-condition-item-view
        margin-top: 5px
  